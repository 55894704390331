import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';

class FavoriteFormBase extends Component {
  constructor(props) {
    super(props);

    const checked = true;
    this.checked = checked;

    const fave = this.props.checkMe;
    this.fave = fave;

    

    const INITIAL_STATE = {
      isFavorite: this.fave,
      error: null,
    };

    this.state = { ...INITIAL_STATE };

  }

  onSubmit = event => {

    event.preventDefault();

    const { isFavorite } = this.state;

    const manual = this.props.pathname;

    const slug = this.props.slug;

    const uri = this.props.uri;

    const title = this.props.title;

    const language = this.props.language;

    const type = this.props.type;

    const manufacturer = this.props.manufacturer;

    const fileURL = this.props.fileURL;




    this.props.firebase
        .doFavoriteUpdate(isFavorite, manual, title, slug, uri, language, type, manufacturer, fileURL);
        /*
        .then(() => {
            console.log('isFave: '+isFavorite+' manual: '+manual);
        })
        .catch(error => {
            this.setState({ error });
        });
        event.preventDefault();
        */
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {

    this.setState({ [event.target.name]: event.target.checked });

    const manual = this.props.pathname;

    const slug = this.props.slug;

    const uri = this.props.uri;

    const title = this.props.title;

    const language = this.props.language;

    const type = this.props.type;

    const manufacturer = this.props.manufacturer;

    const fileURL = this.props.fileURL;


    this.props.firebase.doFavoriteUpdate(event.target.checked, manual, title, slug, uri, language, type, manufacturer, fileURL);

  };

  render() {
    const {
      isFavorite,
      error,
    } = this.state;
    // const manual = this.props.pathname;
    // const slug = this.props.slug;
    // const title = this.props.title;
    // const checkMe = this.props.checkMe;
    // const uri = this.props.uri;
    // const language = this.props.language;
    // const type = this.props.type;
    // const manufacturer = this.props.manufacturer;
    // const fileURL = this.props.fileURL;

    return (

      <form className="favorite" onSubmit={this.onSubmit}>

        <label>
         <span className="visually-hidden">Favorite</span>

         {isFavorite ? <svg aria-hidden="true" data-prefix="fas" data-icon="bookmark" className="file fave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"/></svg> : <FontAwesomeIcon className="file nonFave" icon={faBookmark} /> }

          <input
            id="faved"
            name="isFavorite"
            type="checkbox"
            checked={isFavorite}
            onChange={this.onChangeCheckbox}
          />
        </label>

        <button  type="submit">
          Favorite
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default withFirebase(FavoriteFormBase);
